import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import image from '../../assets/page-images/hakkimizda-image.png';

const NotFound = lazy(() => import('../NotFound'));
const Page = lazy(() => import('./../Page'));
const InternalNumbers = lazy(() =>
  import('../../components/AboutUs/internalNumbers')
);
const SocialMediaPages = lazy(() =>
  import('../../components/AboutUs/OurSocialMediaPages')
);

const SSS = lazy(() => import('../SSS'));
export default function HealthOperations() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/sss`} component={SSS} />
      <Route path={`${path}/dahili-numaralar`} component={InternalNumbers} />
      <Route path={`${path}/sosyalmedya`} component={SocialMediaPages} />
      <Route path={`${path}/:id`}>
        <Page image={image} />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}
