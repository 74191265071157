import React from 'react';
import { styled } from 'ykb-ui';

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 90px;
  z-index: -60;
`;
const PopupMenuWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 416pxpx;
  right: 0px;
  z-index: 1;
  .ykb-menu-submenu {
    border-radius: 4px;
    padding: 25px 4px 4px 4px;
    top: 24px !important;
    background: #ffffff;
    @media (min-width: ${props =>
        props.theme.responsiveSmMin}) and (max-width: ${props =>
        props.theme.responsiveMdMax}) {
      top: 80px !important;
      background: #ffffff;
      padding: 25px 10px 0px;
    }
    &-hidden {
      display: none;
    }
    > ul {
      margin-right: auto;
      margin-left: auto;
      margin-top: -10px;
      flex-direction: column;
      position: relative;
      box-shadow: none;
      background: transparent;
      box-shadow: none;
      border: none;
      > li {
        margin-right: 5px;
      }
      @media (min-width: 768px) {
        width: 100%;
      }
      @media (min-width: 992px) {
        width: 100%;
      }
      @media (min-width: 1200px) {
        width: 340px;
        //min-height: 232px;
      }
    }
  }
`;

const RenderOverlay = ({ openKeys, navVisible }) => {
  return (
    <React.Fragment>
      {window.innerWidth < 768
        ? navVisible && <Overlay className="oooverlay" />
        : openKeys && openKeys.length > 0 && <Overlay />}
    </React.Fragment>
  );
};

export default function PopupContainer({ openKeys, navVisible }) {
  return (
    <div>
      <RenderOverlay openKeys={openKeys} navVisible={navVisible} />
      <PopupMenuWrapper id="popup-container"></PopupMenuWrapper>
    </div>
  );
}
