import React from 'react';
import { Typography, styled } from 'ykb-ui';

const Title = styled(Typography.Title)`
  font-size: 16px !important;
  font-weight: 600 !important;
  color: white !important;
`;
const Paragraph = styled(Typography.Paragraph)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: white !important;
`;

const Wrapper = styled.div`
  display: inline-block;
  white-space: normal;
  margin-top: 25px;
  width: 100%;
  flex: 1 auto;
  @media (max-width: 767px) {
    display: none;
  }
`;

export default function MegamenuDescription({ descTitle, desc }) {
  return (
    <React.Fragment>
      {(descTitle || desc) && (
        <Wrapper>
          {descTitle && <Title>{descTitle}</Title>}
          {descTitle && <Paragraph>{desc}</Paragraph>}
        </Wrapper>
      )}
    </React.Fragment>
  );
}
