import React from 'react';
import { styled, Row, Col } from 'ykb-ui';
import ykbLogoImage from '../../assets/ykb-logo.svg';

const YkbLogo = styled.img.attrs(props => ({
  src: ykbLogoImage,
}))`
  height: 20px;
  margin-right: 20px;
  float: left;
  + span {
    white-space: nowrap;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  height: 44px;
  min-height: 44px;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    min-height: 80px;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    min-height: 40px;
  }
`;

const Brand = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 10px;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    margin-right: -10px;
  }
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    justify-content: center;
  }
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    justify-content: center;
  }
`;

const Contact = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 10px;
  //padding-left: 125px;
  float: right;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    padding-left: 0px;
    justify-content: center;
  }
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    justify-content: center;
  }
`;
const Link = styled.a`
  text-decoration: none !important;
  color: white !important;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 30px;
`;

const LinkContact = styled(Link)``;

const Text = styled.span`
  padding-left: 6px;
  font-size: 12px;
`;

export default function Bottom() {
  return (
    <Wrapper>
      <Row>
        <Col sm={24} lg={12}>
          <Brand>
            <YkbLogo alt="Yapı ve Kredi Bankası A.Ş." />
            <Text className="visible-sm visible-md visible-lg">
              Yapı ve Kredi Bankası A.Ş. Emekli Sandığı Vakfı
            </Text>
          </Brand>
        </Col>
        <Col sm={24} lg={12}>
          <Contact>
            <LinkContact
              href={process.env.PUBLIC_URL + '/hakkimizda/id=35'}
              className="visible-lg"
            >
              Kişisel Verilerin Korunması
            </LinkContact>
            <LinkContact
              href={process.env.PUBLIC_URL + '/hakkimizda/id=34'}
              className="visible-lg"
            >
              Çerez Politikası
            </LinkContact>
          </Contact>
        </Col>
      </Row>
    </Wrapper>
  );
}
