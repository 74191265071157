import React from 'react';
import { styled, Container, Divider as YkbDivider } from 'ykb-ui';
import CookiePolicy from './CookiePolicy';
import Bottom from './Bottom';
import Middle from './Middle';

const ContainerStyled = styled(Container)``;

const Divider = styled(YkbDivider)`
  && {
    background: white;
    opacity: 0.5;
    margin: 0px;
  }
`;

const FooterWrapper = styled.footer`
  background: ${props => props.theme.color.primary6};
  color: #fff;
  display: flex;
  position: relative;
  > div {
    > div {
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        margin-right: 10px;
      }
    }
  }
`;

export default function Footer() {
  return (
    <>
      <FooterWrapper>
        <ContainerStyled xlarge>
          <Divider />
          <Middle />
          <Divider />
          <Bottom />
        </ContainerStyled>
      </FooterWrapper>
      <CookiePolicy />
    </>
  );
}
