import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, css, Spin } from 'ykb-ui';

import { useMenu } from '../../context/MenuContext';
import NavigationItems from './NavigationItems';
import { default as MenuPopupContainer } from './PopupContainer';

const Nav = styled.nav`
  display: flex;
  width: 100%;
  margin-top: -3px;
  .ykb-menu {
    margin: 0px;
    background: transparent;
    display: flex;
    align-items: center;
    border: 0px;
    width: 100%;
    box-shadow: none;
    @media (max-width: ${props => props.theme.responsiveXsMax}) {
      display: block;
    }
    @media (max-width: ${props => props.theme.responsiveSmMax}) {
      display: block;
    }
    .ykb-menu-item,
    .ykb-menu-submenu-title {
      background: transparent !important;
      padding: 0px;
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        padding: 0px !important;
        flex-direction: column;
        display: flex;
        margin-left: 0px;
        max-width: unset;
        justify-content: center;
      }
    }
  }
  //mobil
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    position: fixed;
    width: 300px;
    left: -300px;
    background: #003169;
    top: 93px;
    bottom: 0px;
    transition: all 0.3s ease-in;
    overflow-x: hidden;
    ${props =>
      props.navVisible &&
      css`
        transform: translate(300px);
      `}
  }
  //tablet
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    position: fixed;
    height: 60px;
    top: 32px;
    left: 0px;
    right: 0px;
    background: #003169;
    transition: all 0.1s ease-in;
    z-index: -1;
    transform: translatey(0);
    opacity: 0;
    ${props =>
      props.navVisible &&
      css`
        opacity: 1;
        transform: translatey(60px);
        border-bottom: 1px solid white;
      `}
  }
`;

export default function Navigation({ visible, toggleNav }) {
  const { menu = [], fetchMenu, fetching } = useMenu();
  const history = useHistory();

  useEffect(() => {
    fetchMenu();
  }, []);

  function sonarFunc(images, item) {
    for (const menuItem of item.children.thumbnailMenu) {
      if (menuItem.thumbnail) {
        const image = new Image();
        image.src = menuItem.thumbnail;
        images.push(image);
      }
      if (menuItem.icon) {
        const image = new Image();
        image.src = menuItem.icon;
        images.push(image);
      }
    }
  }

  // preload menu images for better ux
  useEffect(() => {
    const images = [];
    if (menu && menu.length > 0) {
      for (const item of menu) {
        if (
          item.children &&
          item.children.thumbnailMenu &&
          item.children.thumbnailMenu.length > 0
        ) {
          sonarFunc(images, item);
        }
      }
    }
  }, [menu]);

  useEffect(() => {
    if (menu.length > 0 && menu[menu.length - 1].id != 6) {
      menu.push({
        id: 6,
        title: 'Online İşlemler',
        descTitle: null,
        route: null,
        children: {
          listMenu: [
            {
              id: 99,
              title: 'Bireysel İşlemler',
              route: 'https://online.yapikrediemeklisandigi.org/app/login',
            },
          ],
        },
      });
    }
  }, [window.innerWidth < 768 && menu]);

  const [openKeys, setOpenKeys] = useState([]);
  function handleClick(e, item) {
    e.preventDefault();
    if (item.id == 99) {
      window.open(
        'https://online.yapikrediemeklisandigi.org/app/login',
        '_blank'
      );
    } else {
      history.push(item.route);
    }
    toggleNav();
  }

  return (
    <React.Fragment>
      <Nav id="header-navigation" navVisible={visible}>
        <Spin spinning={fetching} text="">
          <NavigationItems
            data={menu}
            handleClick={handleClick}
            openKeys={openKeys}
            setOpenKeys={setOpenKeys}
          />
        </Spin>
      </Nav>

      <MenuPopupContainer openKeys={openKeys} navVisible={visible} />
    </React.Fragment>
  );
}
