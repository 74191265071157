import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, styled, Container, Button, Form } from 'ykb-ui';
import { ReactComponent as EsvLogo } from './esv-logo-beyaz.svg';
import ToggleButton from './ToggleButton';
import Navigation from '../Navigation';
import TopHeader from './TopHeader';
import InputHints from 'react-input-hints';

const StyledContainer = styled(Container)``;
const FormItem = Form.Item;

const HeaderWrapper = styled.header`
  background: ${props => props.theme.color.primary6};
  height: 90px;
  max-height: 90px;
  display: flex;
  position: fixed;
  z-index: 11;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    height: 80px;
  }
  + section {
    margin: 70px 0px -20px 0px;
    padding-bottom: 40px;
    > div {
      @media (max-width: ${props => props.theme.responsiveSmMax}) {
        //margin-left: 10px;
        //margin-right: 10px;
      }
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  ${StyledContainer} {
    @media (max-width: ${props => props.theme.responsiveMdMax}) {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    @media (max-width: ${props => props.theme.responsiveSmMax}) {
      margin-left: 20px;
      margin-right: 20px;
    }
    @media (max-width: ${props => props.theme.responsiveSmMax}) {
      margin-left: 0px;
    }
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    padding-bottom: 10px;
  }
  > div {
    > svg {
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        position: absolute;
        margin-top: -20px;
          }
      }
    }
    @media (max-width: ${props => props.theme.responsiveXsMax}) {
      align-self: self-end;
      text-align: end;
    }
  }
`;

const SearchWrapper = styled.div`
  position: relative !important;
  width: 60% !important;
  //margin-right: 10px;
  margin-top: 2px;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    width: -webkit-fill-available;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    width: ${window.innerWidth}px;
  }
`;

const Logo = styled(EsvLogo)`
  width: 170px;
  height: 50px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    height: 45px;
    margin-right: 20px;
  }
`;

const MobilButton = styled(Button)`
  background-color: transparent !important;
  border-color: transparent !important;
  display: contents;
  font-size: 26px;
`;
const LogoWrapper = styled.div`
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: flex;
    position: absolute;
    width: ${window.innerWidth}px;
    justify-content: center;
  }
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    flex: 1;
  }
`;

const NewSearch = styled(InputHints)`
  width: -webkit-fill-available !important;
  height: 40px !important;
  border-radius: 5px !important;
  border: 0px !important;
  font-size: 14px;
  color: black !important;
  background-color: #fff !important;
  padding-left: 10px !important;
  :focus-visible {
    outline: none;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
  > div {
    line-height: 40px !important;
    > div {
      color: #8c8c8c;
    }
  }
  ::-webkit-input-placeholder {
    color: #8c8c8c;
  }
  svg,
  .ykb-select-arrow {
    color: #fff;
    margin-left: -7px;
  }
`;
const NewSearchButton = styled(Button)`
  position: absolute !important;
  margin-left: -40px !important;
  background-color: #00a8ff !important;
  border-color: #00a8ff !important;
  margin-right: -6px !important;
  border-radius: 0 !important;
  width: 40px !important;
  > span > span > svg {
    font-size: 24px !important;
    margin-left: -7px !important;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    margin-bottom: -2px !important;
  }
`;
export default function Header() {
  const [navVisible, setNavVisible] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowSearch(false);
    }
  }, []);

  const history = useHistory();
  function redirectToHome(e) {
    e.preventDefault();
    history.push('/');
  }
  const toggleNav = () => {
    setNavVisible(!navVisible);
  };
  const formRef = useRef();

  const handleSubmit = (e, error, values) => {
    let deger = document.getElementById('search').value;
    e.preventDefault();
    if (deger.length < 3) {
      if (window.innerWidth < 768) {
        setShowSearch(false);
      }
      return;
    }
    if (!error) {
      window.location.href = process.env.PUBLIC_URL + '/GetSearch?q=' + deger;
    }
  };

  const ListButton = styled(Button)`
    && {
      background-color: ${props => props.theme.color.secondary6} !important;
      color: #ffffff !important;
      text-align: center !important;
      height: 40px !important;
      width: 130px !important;
      border: transparent !important;
      border-radius: 4px !important;
      top: 6px !important;
      font-size: 14px !important;
      margin-right: -10px !important;
      background-size: cover !important;
      display: inline-block !important;
      > span {
        > button {
          display: none !important;
        }
      }
      &:hover {
        border-end-end-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #316fcc !important;
        color: #fff !important;
        > span {
          > button {
            display: inline-block !important;
          }
        }
      }
      @media (max-width: ${props => props.theme.responsiveXsMax}) {
        display: none !important;
      }
    }
  `;
  const SecondListButton = styled(Button)`
    && {
      background-color: #fff !important;
      color: black !important;
      text-align: center !important;
      height: 40px !important;
      width: 130px !important;
      border: transparent !important;
      border-radius: 0 !important;
      top: 40px !important;
      font-size: 14px;
      margin-left: -111px !important;
      background-size: cover !important;
      border-end-end-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      &:hover {
        background-color: #00a8ff !important;
        color: #fff !important;
      }
    }
  `;

  return (
    <HeaderWrapper>
      <StyledContainer xlarge>
        <TopHeader />
        <HeaderContent id="header-nav">
          <ToggleButton navVisible={navVisible} toggleNav={toggleNav} />
          <LogoWrapper>
            <Logo onClick={redirectToHome} />
          </LogoWrapper>

          <Navigation visible={navVisible} toggleNav={toggleNav} />
          <SearchWrapper>
            <Form
              layout="vertical"
              onSubmit={handleSubmit}
              ref={formRef}
              colSpan={{ lg: 24, md: 24, sm: 24 }}
            >
              {showSearch ? (
                <FormItem>
                  <NewSearch
                    id="search"
                    name="search"
                    placeholders={[
                      'Buradan arama yapabilirsiniz...',
                      'Emeklilik...',
                      'Hizmet dökümü...',
                    ]}
                  />
                  <NewSearchButton htmlType="submit">
                    <Icon color="#fff" name="search" />
                  </NewSearchButton>
                </FormItem>
              ) : (
                <FormItem>
                  <MobilButton onClick={() => setShowSearch(true)}>
                    <Icon color="#fff" name="search" />
                  </MobilButton>
                </FormItem>
              )}
            </Form>
          </SearchWrapper>
          <FormItem>
            <ListButton className="fluid hidden-xs">
              Online İşlemler
              <SecondListButton
                className="fluid hidden-xs"
                onClick={() => {
                  window.open(
                    'https://online.yapikrediemeklisandigi.org/app/login',
                    '_blank'
                  );
                }}
              >
                Bireysel İşlemler
              </SecondListButton>
            </ListButton>
          </FormItem>
        </HeaderContent>
      </StyledContainer>
    </HeaderWrapper>
  );
}
