import React from 'react';
import { styled, Container, Icon, Divider as YkbDivider } from 'ykb-ui';

const ContainerStyled = styled(Container)``;

const Divider = styled(YkbDivider)`
  && {
    background: #003169;
    opacity: 1;
    margin: 0px;
    top: -10px;
    margin-left: 0px;
    @media (max-width: ${props => props.theme.responsiveXsMax}) {
      display: none;
    }
  }
`;

const Bosluk = styled.div`
  margin-left: 31px;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  height: 40px;
  top: -10px;
  min-height: 40px;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    margin-left: -30px;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    height: 1px;
  }
`;

const Brand = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  color: #ffffff;
  font-size: 12px;
  padding-bottom: 7px;
`;

const Text = styled.span`
  padding-left: 5px;
`;

const TelText = styled.span`
  margin-top: -35px;
  margin-right: 10px;
  color: white;
  font-size: 12px;
  float: right;
`;

const IconStyle = styled(Icon)`
  margin-top: -30px;
  margin-right: 72px;
  color: white !important;
  font-size: 12px;
  float: right;
`;

export default function TopHeader() {
  return (
    <div>
      <Wrapper>
        <ContainerStyled xlarge>
          <Brand>
            <Icon
              className="visible-sm visible-md visible-lg"
              name="clock"
              size={14}
            ></Icon>
            <Text className="visible-sm visible-md visible-lg">
              İdari Ofisler: Pazartesi-Cuma 09:00 - 18:00
            </Text>
            <Bosluk />
            <Icon
              className="visible-sm visible-md visible-lg"
              name="clock"
              size={14}
            ></Icon>
            <Text className="visible-sm visible-md visible-lg">
              Sağlık Merkezleri: Pazartesi-Cuma 09:00 - 15:00
            </Text>
          </Brand>
        </ContainerStyled>
      </Wrapper>
      <IconStyle
        className="visible-sm visible-md visible-lg"
        name="phone"
        size={14}
      ></IconStyle>
      <TelText className="visible-sm visible-md visible-lg">444 05 95</TelText>
      <Divider />
    </div>
  );
}
