import React, { useRef, useState } from 'react';
import { Button } from 'ykb-ui';
import Modal from './InfoModal/ESVInfoModal';
import { useOnClickOutside } from 'ykb-utils';
import { AskToHRContainer, AskToHRMenuContainer } from './styled.js';

export default function IKyaSorButton() {
  const [modalVisible, setModalVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const askESV = useRef(null);

  function handleClick() {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }

  function handleModalClick(status) {
    setModalVisible(status);
  }

  function handleAdd() {
    setModalVisible(true);
    setShowMenu(false);
  }

  useOnClickOutside(askESV, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  return (
    <>
      <AskToHRContainer ref={askESV}>
        {showMenu && (
          <AskToHRMenuContainer>
            <Button
              type="secondary"
              icon="plus"
              onClick={handleAdd}
              size="medium"
            >
              Yeni Kayıt
            </Button>
          </AskToHRMenuContainer>
        )}
        <Button
          id="ask-esv"
          onClick={handleClick}
          type="primary"
          className="ask-btn"
        >
          ESV'ye Sor
        </Button>
        {modalVisible && (
          <Modal visible={modalVisible} handleVisible={handleModalClick} />
        )}
      </AskToHRContainer>
    </>
  );
}
