import React from 'react';
import 'whatwg-fetch';
import { Message } from 'ykb-ui';

export const queryString = obj =>
  Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => `${key}=${obj[key]}`)
    .join('&');

const sanitizeEndpoint = endpoint =>
  endpoint.charAt(0) !== '/' ? '/' + endpoint : endpoint;

const handleResponse = response => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return response.json().then(json => ({ json, response, text: null }));
  } else {
    return response.text().then(text => ({ json: [], response, text }));
  }
};

const handleBlobResponse = (response, json) =>
  response.blob().then(blob => ({ json, response, blob }));

function func(response) {
  if (
    response.status === 401 &&
    !(window.location.pathname || '').endsWith('/login')
  ) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  }
}

const handleErrors = (response, json, text, apiParams) => {
  if (!response.ok) {
    func(response);

    if (
      !apiParams.silentMode &&
      (response.status === 403 || apiParams.notifyErrors)
    ) {
      const exceptionData = json.exceptionData || {};
      const title = exceptionData.errorCode || response.status;
      const showDetails =
        exceptionData.showExceptionDetails !== false &&
        apiParams.showExceptionDetails;
      let contentText =
        exceptionData.errorMessage ||
        text ||
        'Sunucu beklenmeyen bir durumla karşılaştı, daha sonra tekrar deneyiniz!';

      if (!exceptionData.errorMessage && text) {
        contentText = text;
      } else if (Array.isArray(json)) {
        contentText = json.flatMap(item => Object.values(item)).join(' ');
      }

      Message.error({
        title: 'Uyarı',
        content: (
          <div>
            <div dangerouslySetInnerHTML={{ __html: contentText }} />
            {showDetails && (
              <>
                <br />
                <pre style={{ fontSize: '12px' }}>
                  Hata Kodu: {title} {response.statusText}
                  <br />
                  Olay Takip Kodu: {exceptionData.traceId}
                  <br />
                  Uygulama Adı: {exceptionData.applicationName}
                  <br />
                </pre>
              </>
            )}
          </div>
        ),
      });
    }
    return Promise.reject(json);
  }
  return json;
};

export default function callApi(params = {}) {
  let apiParams = {
    method: 'GET',
    body: undefined,
    url: '',
    notifyErrors: true,
    showExceptionDetails: process.env.NODE_ENV === 'development',
    endpoint: null,
    silentMode: false,
    ...(typeof params === 'object' ? params : { url: params }),
  };

  if (typeof apiParams.endpoint === 'string') {
    apiParams.url = `${process.env.REACT_APP_API}${sanitizeEndpoint(
      apiParams.endpoint
    )}`;
  }

  if (window.onServiceCall) {
    window.onServiceCall(new Date());
  }

  const headers = apiParams.IsFormData
    ? {}
    : { 'Content-Type': 'application/json' };

  return fetch(apiParams.url, {
    method: apiParams.method,
    headers: { ...headers, ...apiParams.headers },
    credentials: 'include',
    body: apiParams.IsFormData
      ? apiParams.body
      : JSON.stringify(apiParams.body),
  })
    .then(handleResponse)
    .then(result =>
      params.file ? handleBlobResponse(result.response, result.json) : result
    )
    .then(({ json, response, text, blob }) =>
      handleErrors(response, json, text, apiParams)
    )
    .then(result => (params.file ? result.blob : result));
}
