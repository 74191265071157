import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Spin } from 'ykb-ui';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';

import AboutUs from './AboutUs';

const Home = lazy(() => import('./Home'));
const HealthOperations = lazy(() => import('./HealthOperations'));
const RetirementOperations = lazy(() => import('./RetirementOperations'));
const FoundationOperations = lazy(() => import('./FoundationOperations'));
const ContactForm = lazy(() => import('./ContactForm'));
const News = lazy(() => import('./News'));
const NotFound = lazy(() => import('./NotFound'));
const SearchResult = lazy(() => import('./Page/SearchResult'));

export default function Routes() {
  let url = '/';
  if (process.env.REACT_APP_PUBLIC_SUBFOLDER) {
    url = process.env.REACT_APP_PUBLIC_SUBFOLDER;
  }

  return (
    <Router basename={`${url}`}>
      <Suspense fallback={<Spin spinning={true} />}>
        <Switch>
          <>
            <Header />
            <Main>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/saglik-islemleri">
                  <HealthOperations />
                </Route>
                <Route path="/getSearch">
                  <SearchResult />
                </Route>
                <Route path="/hakkimizda">
                  <AboutUs />
                </Route>
                <Route path="/iletisim-formu">
                  <ContactForm />
                </Route>
                <Route path="/duyurular-haberler">
                  <Switch>
                    <Route path={'/duyurular-haberler'} component={News} />
                    <Route path={'/duyurular-haberler/:id?'} component={News} />
                  </Switch>
                </Route>
                <Route path="/emeklilik-islemleri">
                  <RetirementOperations />
                </Route>
                <Route path="/vakif-islemleri">
                  <FoundationOperations />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Main>
            <Footer />
          </>
        </Switch>
      </Suspense>
    </Router>
  );
}
