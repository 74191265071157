import { generateTokens } from 'ykb-design-tokens';

const primaryColors = {
  red: '#e60900',
  volcano: '#FA541C',
  orange: '#FA8C16',
  gold: '#FAAD14',
  yellow: '#ffbc00',
  lime: '#A0D911',
  green: '#5fad12',
  cyan: '#00a8ff',
  blue: '#004990',
  geekblue: '#2F54EB',
  purple: '#722ED1',
  magenta: '#EB2F96',
  grey: '#b6b6b6',
};

const tokens = {
  /**
   * appname is used to define default font family atm.
   */
  input: {
    focus: {
      boxShadowColor: 'rgba(87,143,181,0.5)',
    },
  },
  button: {
    focusBoxShadow: '0 0 3px 3px rgba(87,143,181,0.5)',
    type: {
      secondary: {
        textColor: '#00a8ff', // secondary6
        textColorHover: '#0089d9', // secondary7
        textColorPressed: '#006bb3', // secondary8
        textColorDisabled: '#52d1ff', // secondary4

        backgroundColorHover: '#e6fbff', // secondary1
        backgroundColorPressed: '#a3edff', // secondary2

        borderColor: '#00a8ff', // secondary6
        borderColorHover: '#0089d9', // secondary7
        borderColorPressed: '#006bb3', // secondary8
        borderColorDisabled: '#52d1ff', // secondary4
      },
    },
  },
  appname: 'esv',
  customFont: true,
  colorType: {
    primary: 'blue', //blue6
    info: 'blue', //blue6
    secondary: 'cyan', //cyan6
    default: 'grey', //grey6
    success: 'green', //green6
    warning: 'yellow', //yellow6
    danger: 'red', // red6
    error: 'red', //red6
    neutral: 'neutral',
  },
  font: {
    /**
     * sparx: 'OpenSans', Helvetica, Arial, sans-serif
     * law: 'OpenSans', Helvetica, Arial, sans-serif
     * "vendor-loan": 'Ubuntu', Helvetica, Arial, sans-serif;
     * dinamik: 'OpenSans', Helvetica, Arial, sans-serif
     * esv: 'Ubuntu', Helvetica, Arial, sans-serif;
     */
    family: "'Ubuntu', Helvetica, Arial, sans-serif;",
    lineHeight: '1.5em',
    size: '16px',
  },
  /**alttaki 2 property eskiden yazilmisti law styled da, copy paste ettim */
  backgroundColorBase: '#F3F3F6',
  base: {
    fontSize: '16px',
    fontVariant: 'tabular-nums',
    lineHeight: '16px',
    fontFeatureSettings: 'tnum',
  },
};

const generatedTokens = generateTokens({
  colors: primaryColors,
  tokens: tokens,
});

export default generatedTokens;
