import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider, createGlobalStyle } from 'ykb-ui';
import tokens from './esv-design-tokens';
import { mergeDeep } from 'ykb-utils';

import theme from './ThemeVariables';
import { MenuProvider } from './context/MenuContext';
import Routes from './routes';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html{
    height: auto;
  }
  body {
    background-color: ${props => props.theme.color.default1};
    display:flex;
    font-size: 16px;
    line-height: 1.5em;
    width: 100% !important;
    font-family: Ubuntu;

  }
  #root {
    min-height: 100vh;
    height: fit-content !important;
    margin: 0; 
    display: flex;
    flex-flow: column;
    width: 100%;
    > * {
      width: 100%;
      height: 100%;
    }
  }

  .rc-select-selector > div {
    height: 40px;
  }
`;

function App() {
  const tokensMerged = mergeDeep(tokens, theme);
  return (
    <ThemeProvider tokens={tokensMerged} globals>
      <GlobalStyle />

      {/* Menu contextini ucurursak  Provider i kaldiririz */}
      <MenuProvider>
        <Helmet
          encodeSpecialCharacters={true}
          titleTemplate="%s | Yapı Kredi Emekli Sandığı Vakfı"
          defaultTitle="Yapı Kredi Emekli Sandığı Vakfı"
        />
        <Routes />
      </MenuProvider>
    </ThemeProvider>
  );
}

export default App;
