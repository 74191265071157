import React, { useEffect } from 'react';
import { Modal, styled, Col, Card, Typography, Icon } from 'ykb-ui';

import bizesorun from '../../../assets/form-images/bize-sorun-image.png';

const CardAskUs = styled(Card)`
  border: #fff;
  > div {
    padding: 0px;
  }
`;

const ModalWithMargin = styled(Modal)`
  margin-top: -45px !important;
`;

const ColPhoto = styled(Col)`
  padding: 0px;
  > img {
    width: 100%;
    height: 258px;
    object-fit: fill;
    @media (max-width: ${props => props.theme.responsiveSmMax}) {
      object-fit: fill;
      height: 300px;
    }
    @media (max-width: ${props => props.theme.responsiveXsMax}) {
      height: 192px;
      object-fit: fill !important;
    }
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    width: 100%;
  }
`;

const Text = styled(Typography.Text)`
  font-size: 12px;
  letter-spacing: 0px;

  @media (max-width: ${props => props.theme.responsiveSmMax}) {
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
  }
`;

const Link = styled(Typography.Link)`
  font-size: 13px;
  margin-top: 5px;
  color: #00a8ff;
  display: inline-flex;
  > span {
    > svg {
      margin-top: 1px;
    }
  }
`;

export default function ESVInfoModal({ visible, handleVisible }) {
  useEffect(() => {}, []);

  function handleModalClose() {
    handleVisible(false);
  }

  return (
    <>
      <ModalWithMargin
        style={{ marginTop: -20 }}
        visible={visible}
        title="ESV'ye Sor - Kayıt Aç"
        onClose={handleModalClose}
      >
        <CardAskUs>
          <ColPhoto lg={24} md={24} sm={24} xs={12}>
            <img src={bizesorun} alt="Sizinleyiz" />
          </ColPhoto>
          <Typography.Title level={5}>Bize Sorun</Typography.Title>
          <Text strong>YKB Emekli Sandığı üyesiyim.</Text>
          <br />
          <Text>
            {
              "Sizlere daha hızlı dönüş yapabilmemiz için Online İşlemler > ESV'ye Sor > Yeni Kayıt adımından bizimle iletişime geçebilirsiniz."
            }
          </Text>
          <Link
            style={{ marginLeft: 5, marginTop: -2 }}
            href="https://online.yapikrediemeklisandigi.org/app/"
            target="_blank"
          >
            Online İşlemler <Icon size="23" name="angle-right" />
          </Link>
          <br />
          <br />
          <Text strong>YKB Emekli Sandığı üyesi değilim. </Text>
          <br />
          <Text>
            YKB Emekli Sandığı mensubu olmayan kişi veya kurumlar için{' '}
          </Text>
          <Link
            style={{ marginLeft: 5, marginTop: -2 }}
            href={process.env.PUBLIC_URL + '/iletisim-formu'}
          >
            İletişim Formu <Icon size="23" name="angle-right" />
          </Link>
        </CardAskUs>
      </ModalWithMargin>
    </>
  );
}
