import React from 'react';
import { styled, Icon, Button, Typography } from 'ykb-ui';
import classnames from 'classnames';

const _Text = Typography.Text;

const Text = styled(_Text)`
  color: white;
  max-width: 140px;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    max-width: none;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    max-width: none;
  }
`;

const ThumbnailMenu = styled.div`
  max-width: 285px;
  display: flex;
  align-self: flex-start;
  width: 100%;
  padding-right: 20px;
  text-align: center;
  cursor: pointer;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    margin-bottom: 20px;
    max-width: none;
  }
  @media (max-width: ${props => props.theme.responsiveMdMax}) {
    padding-right: 0px;
    max-width: none;
  }
`;

const ThumbnailMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding-bottom: 20px;
  max-width: 75%;
  font-weight: 500;
  flex: 3;
  &.onlyChild {
    position: relative;
    padding: 0px !important;
    margin-bottom: 20px;
  }
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    flex-direction: column;
    flex: 1;
    padding-right: 10px;
    &.onlyChild {
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 0px;
      max-width: unset;
      ${ThumbnailMenu} {
        flex: 50%;
        padding: 0px 10px 0px 20px;
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    position: relative;
    width: auto;
    flex-direction: column;
    padding: 0px !important;
    max-width: unset;
  }
`;

const Box = styled.div`
  display: block;
  background: ${props => props.theme.color.primary6};
  border-radius: 10px;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    background: transparent;
    width: 100%;
    height: auto;
    padding: 10px 0px 10px 40px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    /* top: 120px !important; */
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: none;
  }
`;
const Image = styled.img`
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  transition: all 0.4s;
  border-radius: 10px;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    margin: 0px;
    height: 60px;
  }
`;

const SvgImage = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 75%;
`;

const Detail = styled.div`
  background: ${props => props.theme.color.primary6};
  border-radius: 10px;
  height: 40px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  text-align: left;
  white-space: normal;
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    margin: 0px 10px 0px 0px;
    flex: 1;
    padding-left: 20px;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    margin: 0px;
    background: transparent;
    width: 100%;
    height: auto;
  }
`;

const BoxButton = styled(Button)`
  background: ${props => props.theme.color.primary7};
  color: white;
  justify-content: center;
  border: 0px;
  margin: 0px;
  width: 40px;
  padding: 0px;
  border-radius: 8px;
  transition: all 0.3s;
  svg {
    margin: 0;
    width: auto;
    height: auto;
    vertical-align: middle;
  }
  :hover,
  :focus {
    background: ${props => props.theme.color.primary5};
    color: white;
  }
  :active {
    background: ${props => props.theme.color.primary8};
  }
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    /* top: 120px !important; */
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: none;
  }
`;

const BoxIcon = styled(Icon)`
  width: 20px !important;
  height: 20px !important;
`;

export default function MegaMenuThumbItems({
  data,
  handleNavClick,
  onlyChild,
  children,
}) {
  const cls = classnames({
    [`onlyChild`]: onlyChild,
  });

  return (
    <ThumbnailMenuWrapper className={cls}>
      {data.map(item => (
        <ThumbnailMenu
          key={item.id}
          onClick={e => {
            handleNavClick(e, item);
          }}
        >
          <Box>
            <ImageContainer>
              <Image alt={item.title} src={item.thumbnail} />
              <SvgImage src={item.icon} />
            </ImageContainer>

            <Detail>
              <Text>{item.title}</Text>
              <BoxButton type="primary">
                <BoxIcon size={18} name="arrow-right" />
              </BoxButton>
            </Detail>
          </Box>
        </ThumbnailMenu>
      ))}
      {children}
    </ThumbnailMenuWrapper>
  );
}
