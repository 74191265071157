export const mobilePhoneValidation = message => ({
  pattern: /^5+\d{9}$/,
  message: message,
});

const regExNumeric = new RegExp(/^\d+$/i);
const regExAlphaNumeric = new RegExp(/^[A-z0-9şçöğüıŞÇÖĞÜİ]+$/i);
const regExAlphaPlusWhiteSpace = new RegExp(
  /^[A-z0-9şçöğüıŞÇÖĞÜİ]+\s?([A-z0-9şçöğüıŞÇÖĞÜİ]+\s?)*$/i
);

const allowedInputCheck = (e, form, regExToUse) => {
  const vl = e.target.value;
  if (vl !== '' && !regExToUse.test(vl)) {
    e.target.value = form.fieldsStore.getFieldsValue()[e.target.name];
  }
};

export const allowNumeric = (e, form) => {
  allowedInputCheck(e, form, regExNumeric);
};

export const allowAlphaNumeric = (e, form) => {
  allowedInputCheck(e, form, regExAlphaNumeric);
};

export const allowAlphaPlusWhiteSpace = (e, form) => {
  allowedInputCheck(e, form, regExAlphaPlusWhiteSpace);
};

export const tcknValidator = (rule, value, callback) => {
  if (value.length === 11 && checkTcknWithTcknAlgorithm(value)) {
    callback();
  } else {
    callback([new Error('Lütfen geçerli bir TCKN giriniz.')]);
  }
};

const checkTcknWithTcknAlgorithm = tckn => {
  tckn = tckn.toString();

  if (!/^\d{11}$/.test(tckn)) {
    return false;
  }
  let totalX = 0;
  for (let i = 0; i < 10; i++) {
    totalX += Number(tckn.substr(i, 1));
  }
  const isRuleX = totalX % 10 == tckn.substr(10, 1);
  let totalY1 = 0;
  let totalY2 = 0;
  for (let i = 0; i < 10; i += 2) {
    totalY1 += Number(tckn.substr(i, 1));
  }
  for (let i = 1; i < 10; i += 2) {
    totalY2 += Number(tckn.substr(i, 1));
  }
  const isRuleY = (totalY1 * 7 - totalY2) % 10 == tckn.substr(9, 0);
  return isRuleX && isRuleY;
};

export function browserDetected(browser) {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) != -1
  ) {
    browser = 'Opera';
  } else if (navigator.userAgent.indexOf('Edg') != -1) {
    browser = 'Edge';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    browser = 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    browser = 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    browser = 'Firefox';
  } else if (
    navigator.userAgent.indexOf('MSIE') != -1 ||
    !!document.documentMode
  ) {
    browser = 'IE';
  } else {
    browser = 'unknown';
  }
  return browser;
}
