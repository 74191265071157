import React, { useRef, useState } from 'react';
import { styled, Button } from 'ykb-ui';
import Modal from './Modal';
import { useOnClickOutside } from 'ykb-utils';

const LinkButton = styled(Button)`
  && {
    border-color: white !important;
    margin-top: 15px;
    @media (max-width: ${props => props.theme.responsiveSmMax}) {
      display: none !important;
    }
  }
`;

export default function IKyaSorButton() {
  const [modalVisible, setModalVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const Text = styled.span`
    padding-left: 5px;
    font-size: 14px;
  `;

  const askESV = useRef(null);

  function handleModalClick(status) {
    setModalVisible(status);
  }

  useOnClickOutside(askESV, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  function openModel() {
    setModalVisible(true);
  }

  return (
    <div>
      <LinkButton type="primary" icon="download" onClick={() => openModel()}>
        <Text className="visible-sm visible-md visible-lg">
          ESV Mobil İndir
        </Text>
      </LinkButton>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          handleVisible={handleModalClick}
          prefixCls={'test-prefix'}
          className="modalMobil"
        />
      )}
    </div>
  );
}
