import React from 'react';
import { styled, Button } from 'ykb-ui';

const Line = styled.span`
  margin: 6px 0px;
  width: 24px;
  height: 2px;
  background: white;
  display: block;
  transition: all 0.3s;
  &:after {
    clear: both;
    content: '';
  }
`;

const LineFirst = styled(Line)``;
const LineSecond = styled(Line)``;
const LineThird = styled(Line)`
  width: 15px;
`;

const Btn = styled(Button)`
  background: #004990 !important;
  box-shadow: none;
  border-radius: 0px !important;
  border: 0px !important;
  color: white;
  height: 100%;
  border-radius: none;
  &.active {
    background: ${props => props.theme.color.primary8} !important;

    ${LineFirst} {
      transform: rotate(45deg) scale(1.1) translate(6px, 7px);
    }
    ${LineSecond} {
      transform: rotate(135deg) scale(1.1) translate(2px, -1px);
    }
    ${LineThird} {
      transform: rotate(135deg) scale(1.1) translate(2px, -1px);
      width: 0px;
    }
  }
`;

export default function ToggleButton({ navVisible, toggleNav }) {
  return (
    <Btn
      className={`visible-sm visible-md visible-xs ${navVisible && 'active'}`}
      onClick={toggleNav}
    >
      <LineFirst />
      <LineSecond />
      <LineThird />
    </Btn>
  );
}
