import React, { useState, useEffect } from 'react';
import { styled, Typography, Icon, Button, Col, Spin } from 'ykb-ui';
import callApi from '../../utils/callApi';
import ip from 'ip';
import { storage } from 'ykb-utils';
import { browserDetected } from '../../utils/validations';
const Cookie = styled.div`
  background-color: #f2f2f2;
  position: sticky;
  box-shadow: 10px 25px 25px 20px;
  bottom: 0px;
  z-index: 1000;
  height: 100px !important;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    height: 135px !important;
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    height: 220px !important;
  }
  padding-top: 10px;
  padding-left: 10px;
`;

const Buttons = styled(Button)`
  color: #000000 !important;
  background-color: #dbdbdb !important;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%) !important;
  width: 120px;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    margin-top: 5px;
  }
`;

const CookieText = styled(Typography.Text)`
  font-size: 12px;
  color: #000;
`;

export default function CookiePolicy() {
  const [showCookie, setShowCookie] = useState(true);
  const [loading, setLoading] = useState(false);

  let browser;
  useEffect(() => {
    let cookie = storage.getCookie('cerez');
    if (cookie) {
      setShowCookie(false);
    }
  }, []);

  function CookieClick(set, insert) {
    storage.setCookie('cerez', set, { secure: true });
    browser = browserDetected(browser);
    insertCookie(insert);
    setShowCookie(false);
  }

  const insertCookie = async function(values) {
    try {
      setLoading(true);
      await callApi({
        endpoint: 'Logs/InsertCookieLog',
        body: {
          browser: browser,
          clientIpAdress: ip.address(),
          jsonData:
            '{"FunctionalityAndPreferenceCookies":' +
            values +
            ',"PerformanceAndAnalysisCookies":0,"TargetingAndAdvertising":0}',
          Status: values,
          ...values,
        },
        method: 'POST',
      });
    } finally {
      setLoading(false);
    }
  };

  function styleCol() {
    if (window.innerWidth > 400 && window.innerWidth < 769) {
      return { marginTop: 10 };
    } else return {};
  }

  function styleCol2() {
    if (window.innerWidth < 400) {
      return { textAlign: 'center' };
    } else if (window.innerWidth < 769) {
      return { textAlign: 'end', marginTop: -15 };
    } else return { textAlign: 'end' };
  }

  return showCookie ? (
    <Cookie>
      <Col lg={24} md={24} sm={24} xs={24}>
        <Col lg={1} md={1} sm={2} xs={3}>
          <Icon name="info" size="medium" />
        </Col>

        <span>İnternet Sitemizde Kullanılan Çerezler</span>
      </Col>
      <br className="hidden-xs" />
      <Col lg={15} md={15} sm={19} xs={24} style={styleCol()}>
        <CookieText>
          <Spin spinning={loading}>
            Size en iyi deneyimi sunmak için web sitemizde çerezler
            kullanılmaktadır. Çerezler, sunmuş olduğumuz hizmetlerin size özel
            ve tercihlerinize göre kişiselleştirilmiş içerikler olarak
            sunulmasına imkan tanır.
            <Typography.Link
              href={process.env.PUBLIC_URL + '/hakkimizda/id=34'}
              target="_blank"
            >
              <span> Çerez Politika 'mızı </span>
            </Typography.Link>
            inceleyerek çerezler hakkında daha fazla bilgi alabilirsiniz.
          </Spin>
        </CookieText>
      </Col>
      <Col lg={8} md={8} sm={5} xs={24} style={styleCol2()}>
        <Buttons onClick={() => CookieClick(1, '1')}>Kabul Et</Buttons>
        <Buttons onClick={() => CookieClick(0, '0')}>Reddet</Buttons>
      </Col>
    </Cookie>
  ) : (
    <></>
  );
}
