import React, { useState, useEffect, useId } from 'react';
import { styled, Row, Col, Typography, Button } from 'ykb-ui';
import iosImage from '../../assets/apple-store-button.png';
import androidImage from '../../assets/android-store-button.png';
import igImage from '../../assets/instagram.svg';
import fbImage from '../../assets/facebook.svg';
import medlineImage from '../../assets/medline.png';
import esv from '../../assets/form-images/esv-qr.png';
import belgeqr from '../../assets/form-images/belgeqr.png';
import callApi from '../../utils/callApi';
import MobilAppDownloadModal from '../MobilAppDownloadModal/Modal';
import MobilAppDownloadButton from '../MobilAppDownloadModal/index';
import Modal from '.././AskButton/InfoModal/ESVInfoModal';

const GroupContainer = styled(Row)`
  padding: 20px 0px 0px 10px;
  @media (max-width: ${props => props.theme.responsiveSmMax}) {
    margin-bottom: 15px;
  }
  > div {
    margin-bottom: 20px;
  }
`;

const Title = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: white;
    margin-bottom: 16px;
  }
`;

const List = styled.ul`
  font-size: 12px;
  font-weight: 300;
  list-style: none;
  margin: 0px;
  margin-bottom: 0px;
  padding: 0px;
`;

const ListItem = styled.li`
  :before {
    content: '';
    margin-right: 10px;
    width: 4px;
    height: 4px;
    max-height: 4px;
    background: white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -3px;
  }
  cursor: pointer;
`;

const MedlineWrapper = styled.li`
  background-color: white;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.color.primary6};
  justify-content: space-between;
  width: 165px;
  display: flex;
  padding: 4px 10px;
  border-radius: 5px;
  text-decoration: none;
  align-items: center;
  margin-top: 16px;
  img {
    align-self: center;
  }
`;

const QrWrapper = styled.div`
  max-width: 90px;
  background-color: white;
  border-radius: 5px;
  margin-left: 12px;
  padding: 8px;
  overflow: hidden;
  line-height: 0px;
  display: block;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`;

const SocialButton = styled(Button)`
  && {
    background-color: ${props => props.theme.color.primary7} !important;
    padding: 0px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    :hover {
      background-color: ${props => props.theme.color.primary8} !important;
    }
    :active {
      background-color: ${props => props.theme.color.primary9} !important;
    }
  }
`;

const Link = styled.a`
  text-decoration: none !important;
  color: white !important;
  height: 100%;
  display: flex;
  font-size: 12px;
`;

const MobilVisible = styled.div`
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: none;
  }
`;

const DownloadVisible = styled(Button)`
  display: none;
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    display: block;
    margin-bottom: 5px;
    background-color: #004990 !important;
    border-color: #004990 !important;
  }
`;
const MobilBelgeButton = styled(Button)`
  background-color: ${props => props.theme.color.primary6} !important;
  border-color: white !important;
  border: 2px solid;
  margin-bottom: 15px;
  margin-top: -5px;
  :hover {
    background-color: #003169 !important;
    border-color: #003169 !important;
  }
`;
const LinkContact = styled(Link)``;

function handleClick(url) {
  window.open(url, '_blank');
}

export default function Middle() {
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [mobilModalVisible, setMobilModalVisible] = useState(false);
  const id = useId();

  function handleModalClick(status) {
    setModalVisible(status);
  }
  function handleMobilModalClick(status) {
    setMobilModalVisible(status);
  }
  useEffect(() => {
    getLinks();
  }, []);

  const getLinks = async function() {
    try {
      const response = await callApi({
        endpoint: 'ImportantLinks/GetAll',
      });

      setData(response.data);
    } finally {
      console.log('-');
    }
  };

  return (
    <div id="footer-help">
      <Row>
        <GroupContainer>
          <Col xs={24} sm={12} md={12} lg={5}>
            <Title>Yardım</Title>
            <List>
              <LinkContact href={process.env.PUBLIC_URL + '/hakkimizda/sss'}>
                Sıkça Sorulan Sorular
              </LinkContact>
              <LinkContact
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleModalClick(true);
                }}
              >
                İletişim
              </LinkContact>
            </List>
            <MedlineWrapper href="">
              <img src={medlineImage} alt="Medline" />
              <div>444 12 12</div>
            </MedlineWrapper>
          </Col>

          <Col xs={24} sm={12} md={12} lg={7}>
            <Title>Faydalı Linkler</Title>
            <List>
              {data.map(item => {
                return (
                  <ListItem
                    key={id}
                    onClick={() => {
                      handleClick(item.link);
                    }}
                  >
                    {item.baslik}
                  </ListItem>
                );
              })}
            </List>
          </Col>
          <MobilVisible>
            <Col xs={24} sm={6} md={6} lg={4}>
              <Title>
                Emekli Sandığı Mobil <br /> Uygulamasını İndir!
              </Title>
              <QrWrapper>
                <img
                  onClick={() => {
                    handleMobilModalClick(true);
                  }}
                  src={esv}
                  alt="QR"
                />
              </QrWrapper>
            </Col>
            <Col xs={24} sm={6} md={6} lg={4}>
              <Title>
                QR Kod İle Belge <br /> Doğrulama!
              </Title>
              <QrWrapper>
                <img
                  onClick={() => {
                    handleClick(
                      'https://online.yapikrediemeklisandigi.org/app/belge-dogrulama'
                    );
                  }}
                  src={belgeqr}
                  alt="QR"
                />
              </QrWrapper>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <Title>Bizi Takip Edin!</Title>
              <SocialButton
                type="primary"
                onClick={() => {
                  handleClick(
                    'https://www.facebook.com/yapikrediemeklisandigi/'
                  );
                }}
              >
                <img src={fbImage} alt="Facebook Profilimiz" />
              </SocialButton>
              <SocialButton
                onClick={() => {
                  handleClick(
                    'https://www.instagram.com/yapikrediemeklisandigi/'
                  );
                }}
                type="primary"
              >
                <img src={igImage} alt="İnstagram Profilimiz" />
              </SocialButton>
              <MobilAppDownloadButton />
              {mobilModalVisible && (
                <MobilAppDownloadModal
                  visible={mobilModalVisible}
                  handleVisible={handleMobilModalClick}
                />
              )}
            </Col>
          </MobilVisible>
          {window.innerWidth < 400 ? (
            <div>
              <Col xs={24} style={{ textAlign: 'center' }}>
                <MobilBelgeButton
                  onClick={() => {
                    handleClick(
                      'https://online.yapikrediemeklisandigi.org/app/belge-dogrulama'
                    );
                  }}
                >
                  <Typography.Text style={{ color: 'white' }}>
                    Belge Doğrulama
                  </Typography.Text>
                </MobilBelgeButton>
              </Col>
              <Col xs={12}>
                <DownloadVisible
                  onClick={() => {
                    handleClick(
                      'https://apps.apple.com/tr/app/esv-mobil/id1609917748'
                    );
                  }}
                >
                  <img src={iosImage} height={40} alt="QR" />
                </DownloadVisible>
              </Col>
              <Col xs={12}>
                <DownloadVisible
                  onClick={() => {
                    handleClick(
                      'https://play.google.com/store/apps/details?id=com.ykb.esv'
                    );
                  }}
                >
                  <img src={androidImage} height={40} alt="QR" />
                </DownloadVisible>
              </Col>
            </div>
          ) : (
            ''
          )}
        </GroupContainer>
      </Row>
      {modalVisible && (
        <Modal visible={modalVisible} handleVisible={handleModalClick} />
      )}
    </div>
  );
}
