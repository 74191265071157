import React from 'react';
import { Button, styled } from 'ykb-ui';

const ListMenuWrapper = styled.div`
  flex: 1;
`;

const ListItemButton = styled(Button)`
  width: 100%;
  border-radius: 4px;
  padding: 0px 5px 0px 20px;
  font-size: 14px;
  text-align: left;
  border: 0px !important;
  font: normal normal normal 14px/16px Ubuntu;
  letter-spacing: 0px;

  .ykb-icon {
    position: absolute;
    right: 5px;
    top: 8px;
  }

  .text {
    color: #1f1f1f;
    @media (max-width: ${props => props.theme.responsiveXsMax}) {
      color: #fff !important;
    }
    justify-content: space-between;
    width: 100%;
    text-overflow: ellipsis;
    flex: 1;
    display: flex;
    align-items: center;
    white-space: normal;
    svg {
      width: auto;
      height: auto;
      margin: 0px;
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    color: #1f1f1f !important;
    padding: 10px 0px 10px 40px;
    height: auto;
    background: transparent !important;
    .ykb-icon,
    svg {
      display: none;
    }
  }
`;

const ListItem = styled.div`
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-left: 5px;
  background: transparent;
  &:hover,
  &:focus {
    ${ListItemButton} {
      background: #f5f5f5;
    }
  }
  &:active {
    ${ListItemButton} {
      background: #f5f5f5;
    }
  }
  @media (min-width: ${props =>
      props.theme.responsiveSmMin}) and (max-width: ${props =>
      props.theme.responsiveMdMax}) {
    padding: 0px 0px 0px 10px !important;
    background: transparent !important;
  }

  @media (max-width: ${props => props.theme.responsiveXsMax}) {
    flex-direction: column;
    padding: 0px;
    margin: 0px;
  }
`;

export default function MegaMenuListItems({ data, handleNavClick }) {
  return (
    <ListMenuWrapper>
      {data.map(item => (
        <ListItem key={item.id}>
          <ListItemButton
            onClick={e => {
              handleNavClick(e, item);
            }}
            type="secondary"
          >
            {item.title}
          </ListItemButton>
        </ListItem>
      ))}
    </ListMenuWrapper>
  );
}
