const theme = {
  color: {
    neutral11: '#2C2D2F',
  },
  responsiveXsMax: '767px',
  responsiveSmMin: '768px',
  responsiveSmMax: '991px',
  responsiveMdMin: '992px',
  responsiveMdMax: '1199px',
  responsiveLgMin: '1200px',
};

export default theme;
